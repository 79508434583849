.Nav-container {
	height: 100vh;
	width: 5vw;
	background: linear-gradient(217deg, #1cfbffcc, rgba(255, 0, 0, 0) 70.71%),
		linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
		linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px;
	box-sizing: border-box;
}
