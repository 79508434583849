.App {
	height: 100vh;
	display: flex;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

button:focus {
	outline: none;
}

textarea:focus {
	outline: none;
	background: darkslategrey !important;
	color: white;
}

textarea:focus::placeholder {
	color: white;
}

#run-code-btn:hover,
#run-code-btn:active {
	background: lightgreen !important;
	color: darkslategrey !important;
	content: "help";
}
